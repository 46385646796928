import React from "react"

import Logo from "../images/k.svg"
// import { Toggle } from "./themeToggler"

const Header = () => {
  return (
    <div className="p-8 w-full flex items-center justify-between">
      <img
        src={Logo}
        alt="round logo shown like the front of a coin"
        className="object-center h-9 w-9"
      />
      {/* <Toggle /> */}
    </div>
  )
}

export default Header
