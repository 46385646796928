import React from "react"

import { ThemeProvider } from "./themeContext"

import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <ThemeProvider>
        <Header />
        <main className="flex-grow items-center px-6">
            {children}
        </main>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default Layout
