import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import PortableText from "@sanity/block-content-to-react"

const Contact = ({ data }) => {
  const cmsbody = data.allSanityPage.nodes[0]._rawBody
  
  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      )
    }
  }
  
  return (
    <Layout>
      <h1>yah</h1>
      <PortableText blocks={cmsbody} serializers={serializers} />
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    allSanityPage(filter: { slug: { current: { eq: "contact" } } }) {
      nodes {
        _rawBody
      }
    }
  }
`

export default Contact
